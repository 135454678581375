
  import { defineComponent, ref, onMounted, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'ire-investment-property-age-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(
          translate('ireInvestmentPropertyAgeListing'),
          [translate('ire')]
        );
      });

      await store.dispatch(Actions.GET_IRE_INVESTMENT_PROPERTY_AGES);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('titleEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('titleAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);

      const editIreType = (id) => {
        router.push({
          name: 'ire-investment-property-age-editing',
          params: { id },
        });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(Actions.UPDATE_IRE_CONFIG_CURRENT_PAGE, val);
        await store.dispatch(Actions.GET_IRE_INVESTMENT_PROPERTY_AGES);
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.getireInvestmentPropertyAge),
        tableHeader,
        translate,
        totalItems: computed(() => store.getters.ireInvestmentConfigListCount),
        pageChanged,
        goTo,
        editIreType,
        loading,
        can,
      };
    },
  });
